import React from 'react';

import styles from './style/upsell.css';

export class Upsell extends React.PureComponent {
  signUp = () => {
    window.open('http://marvelapp.com/signup');
  };
  render() {
    return (
      <div className={styles.upsell}>
        <div className={styles.content}>
          <h2 className={styles.title}>Don’t have a prototype?</h2>
          <p className={styles.subtitle}>
            Join over two million creatives using Marvel everyday to make the
            products you know and love.
          </p>
          <div onClick={this.signUp} className={styles.button}>
            Sign up for free
          </div>
        </div>
      </div>
    );
  }
}

export default Upsell;

import React from 'react';

export const YellowArrow = () => (
  <svg width="72" height="143" viewBox="0 0 72 143">
    <g fill="none">
      <path stroke="#FD0" strokeWidth="40" d="M35 .208v124.104" />
      <path fill="#FD0" d="M35.537 142.312l36.463-24h-72z" />
    </g>
  </svg>
);

import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { Helmet } from 'react-helmet';

import HomePage from '../HomePage';
import SitemapPage from '../SitemapPage';
import NotFoundPage from '../NotFoundPage';
import SelectProjectPage from '../../components/SelectProjectPage';

import OAuthTokenRetrieve from '../../components/OAuthTokenRetrieve';
import OAuthTokenStart from '../../components/OAuthTokenStart';

import { AuthTokenKey, GraphqlEndpoint } from '../../utils/config';

class App extends Component {
  constructor(props) {
    super(props);
    // Get the Access Token from local storage
    const authToken = localStorage.getItem(AuthTokenKey);

    // Set the Graphql endpoint
    const httpLink = createHttpLink({
      uri: GraphqlEndpoint,
    });

    const authLink = setContext((_, { headers }) => {
      // get the authentication token from local storage if it exists
      const token = authToken;
      // return the headers to the context so httpLink can read them
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : authToken,
        },
      };
    });

    const errorLink = onError(({ networkError, graphQLErrors }) => {
      if (graphQLErrors) {
        const oauth2Error = `OAuth2 token expired or not provided`;

        graphQLErrors.forEach(({ message, locations, path }) => {
          if (message === oauth2Error) {
            this.props.history.push(`/`);
          }
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          );
        });
      }
      if (networkError) console.log(`[Network error]: ${networkError}`);
    });

    const link = ApolloLink.from([authLink, errorLink, httpLink]);

    this.client = new ApolloClient({
      link,
      cache: new InMemoryCache(),
    });
  }

  render() {
    return (
      <ApolloProvider client={this.client}>
        <div>
          <Helmet
            title="Userflows | Create user flows and journey maps from Marvel prototypes"
            meta={[
              { name: 'description', content: '' },
              { name: 'keywords', content: '' },
            ]}
          />
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/:id" component={SitemapPage} />
            <Route exact path="/oauth/" component={OAuthTokenStart} />
            <Route exact path="/select-project" component={SelectProjectPage} />
            <Route
              exact
              path="/oauth/callback"
              component={OAuthTokenRetrieve}
            />
            <Route component={NotFoundPage} />
          </Switch>
        </div>
      </ApolloProvider>
    );
  }
}

export default withRouter(App);

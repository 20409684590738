import React from 'react';

export const PinkArrow = () => (
  <svg height="65" viewBox="0 0 883 65" width="883">
    <g fill="none" fillRule="evenodd">
      <path
        d="m1.38118548 2.61991203h862.29142652"
        stroke="#ffa2bd"
        strokeWidth="40"
        transform="translate(-.415 30)"
      />
      <path
        d="m11.589 44.806 32.411-24h-64z"
        fill="#ffa2bd"
        transform="matrix(0 1 1 0 838.194 20.806)"
      />
    </g>
  </svg>
);

import React from 'react';

export const Twitter = () => (
  <svg width="30" height="30" viewBox="0 0 30 30">
    <path
      fill="#8792A1"
      fillRule="evenodd"
      d="M15,0 C10.859355,0 7.32423375,1.46482875 4.39453125,4.39453125 C1.46482913,7.32423375 0,10.859355 0,15 C0,19.140645 1.46482913,22.6757663 4.39453125,25.6054688 C7.32423375,28.5351709 10.859355,30 15,30 C19.140645,30 22.6757663,28.5351709 25.6054688,25.6054688 C28.5351713,22.6757663 30,19.140645 30,15 C30,10.859355 28.5351713,7.32423375 25.6054688,4.39453125 C22.6757663,1.46482875 19.140645,0 15,0 L15,0 Z M22.1237785,11.528 C22.1628677,11.6773345 22.1824104,11.8266655 22.1824104,11.976 C22.1824104,14.5146798 21.2247645,16.819991 19.3094463,18.8919982 C17.3941281,20.964009 14.8338925,22 11.6286645,22 C9.59608245,22 7.71987971,21.4773381 6,20.432 C6.27361689,20.4693345 6.56677274,20.488 6.87947883,20.488 C8.56026934,20.488 10.0846843,19.9840036 11.4527687,18.976 C10.6319181,18.9386655 9.90879979,18.6960036 9.28338762,18.248 C8.65797921,17.7999964 8.22801303,17.2213381 7.99348534,16.512 C8.22801303,16.5493345 8.46254072,16.568 8.6970684,16.568 C9.00977449,16.568 9.34201954,16.5306655 9.69381107,16.456 C8.83387122,16.2693309 8.1205243,15.8586691 7.55374405,15.224 C6.98696756,14.5893309 6.70358306,13.8426726 6.70358306,12.984 L6.70358306,12.928 C7.21172764,13.2266691 7.77850038,13.376 8.40390879,13.376 C7.27035205,12.6666619 6.70358306,11.6960072 6.70358306,10.464 C6.70358306,9.82933094 6.87947883,9.23200358 7.23127036,8.672 C9.18567776,10.9866798 11.7263669,12.2186655 14.8534202,12.368 C14.7752456,12.1066655 14.7361564,11.8266691 14.7361564,11.528 C14.7361564,10.5573274 15.0977155,9.72667085 15.8208488,9.03599821 C16.5439783,8.34532915 17.4136745,8 18.4299674,8 C19.524435,8 20.423449,8.37333094 21.1270358,9.12 C21.9869757,8.97066547 22.7687259,8.69066906 23.4723127,8.28 C23.1986958,9.13867264 22.6710123,9.79199642 21.8892508,10.24 C22.5928376,10.1653345 23.2964132,9.97866906 24,9.68 C23.4918554,10.3893381 22.8664545,11.0053309 22.1237785,11.528 L22.1237785,11.528 Z"
    />
  </svg>
);

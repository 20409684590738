import React from 'react';

export const Icon2 = () => (
  <svg width="100%" viewBox="0 0 185 154" xmlns="http://www.w3.org/2000/svg">
    <g fill="none">
      <path fill="#fff" d="M-626-1711h1440v2843h-1440z" />
      <path fill="#fff" d="M0 0h185v154h-185z" />
      <g transform="translate(54 3)">
        <ellipse
          id="Oval"
          fill="#fd0"
          transform="rotate(-5.71 11.342 20.956)"
          cx="11.342"
          cy="20.956"
          rx="6.047"
          ry="6.087"
        />
        <path
          d="M.639 17.643c-.094-.94.598-1.779 1.545-1.873l8.582-.854.169 1.703c.094.94-.598 1.779-1.545 1.873l-6.868.683c-.947.094-1.79-.592-1.883-1.532zM17.353 20.355l4.867 49.061c.334 3.345-2.089 6.329-5.412 6.665-3.323.336-6.287-2.103-6.621-5.448l-4.867-49.061c-.216-2.164.731-4.279 2.484-5.549 1.753-1.27 4.047-1.502 6.016-.609 1.969.894 3.316 2.777 3.532 4.941z"
          id="Shape"
          fill="#fd0"
        />
        <path
          d="M16.809 76.087c1.6-.154 3.073-.943 4.094-2.192 1.021-1.249 1.506-2.857 1.348-4.467l-2.237-22.522-11.821 3.372 2.001 20.33c.153 1.611.936 3.094 2.178 4.122 1.241 1.028 2.838 1.516 4.437 1.357z"
          id="Shape"
          fill="#FFCD01"
        />
        <ellipse
          fill="#fd0"
          transform="rotate(-84.29 59.972 20.972)"
          cx="59.972"
          cy="20.972"
          rx="6.047"
          ry="6.087"
        />
        <path
          d="M60.365 16.631l.169-1.703 8.582.854c.947.094 1.638.933 1.545 1.873-.094.94-.937 1.626-1.883 1.532l-6.862-.683c-.456-.044-.876-.266-1.166-.618-.291-.352-.429-.804-.384-1.256zM53.941 20.361l-4.867 49.03c-.216 2.164.731 4.279 2.484 5.549 1.753 1.27 4.047 1.502 6.016.609 1.969-.894 3.316-2.777 3.532-4.941l4.867-49.061c.216-2.164-.731-4.279-2.484-5.549-1.753-1.27-4.047-1.502-6.016-.609-1.969.894-3.316 2.777-3.532 4.941v.03z"
          id="Shape"
          fill="#fd0"
        />
        <path
          d="M54.491 76.087c-1.6-.154-3.073-.943-4.094-2.192-1.021-1.249-1.506-2.857-1.348-4.467l2.237-22.522 11.821 3.372-2.001 20.33c-.153 1.611-.936 3.094-2.178 4.122-1.241 1.028-2.838 1.516-4.437 1.357z"
          id="Shape"
          fill="#FFCD01"
        />
        <path
          d="M35.674 77.097v62.696"
          id="Shape"
          stroke="#fd0"
          strokeWidth="24.348"
        />
        <path
          d="M35.674 77.097v25.261"
          id="Shape"
          stroke="#FFCD01"
          strokeWidth="24.348"
          strokeLinejoin="round"
        />
        <path
          d="M33.86 35.177l-32.851 47.32c-.482.695-.54 1.602-.151 2.354s1.16 1.224 2.002 1.226h65.713c.842-.002 1.613-.474 2.002-1.226.389-.752.33-1.659-.151-2.354l-32.851-47.32c-.423-.612-1.116-.976-1.856-.976s-1.434.365-1.856.976z"
          id="Shape"
          fill="#fd0"
        />
        <path
          d="M29.138 65.252c.05-1.132-.521-2.2-1.488-2.781-.966-.581-2.171-.581-3.138 0-.966.581-1.538 1.649-1.488 2.781M48.596 65.252c.05-1.132-.521-2.2-1.488-2.781-.966-.581-2.171-.581-3.138 0-.966.581-1.538 1.649-1.488 2.781"
          id="Shape"
          stroke="#592B19"
          strokeWidth="1.779"
          strokeLinecap="square"
        />
        <path
          d="M41.503 68.484c.068 2.092-1.002 4.055-2.791 5.121-1.789 1.066-4.013 1.066-5.803 0-1.789-1.066-2.859-3.029-2.791-5.121"
          id="Shape"
          fill="#fff"
        />
        <path
          d="M45.252 1.826c-2.996-2.477-7.419-2.041-9.88.974l-.526.639-.635-.53c-2.996-2.477-7.419-2.041-9.88.974-2.461 3.016-2.028 7.468.967 9.946l11.458 9.532v-.03h.03l9.402-11.565c2.464-3.007 2.045-7.454-.937-9.94z"
          id="Shape"
          fill="#FD93B2"
        />
        <path
          d="M76.343 97.452c-.926-.771-2.118-1.141-3.315-1.026-1.196.114-2.299.702-3.064 1.635l-.339.414-.411-.341c-.926-.771-2.118-1.141-3.315-1.026-1.196.114-2.299.702-3.064 1.635-.766.932-1.133 2.133-1.02 3.337.113 1.204.698 2.314 1.624 3.085l7.395 6.154 6.047-7.481c1.587-1.918 1.347-4.764-.538-6.385zM10.225 115.287c-.653-.54-1.493-.797-2.335-.714-.841.083-1.616.5-2.152 1.158l-.236.292-.29-.237c-.653-.54-1.493-.797-2.335-.714-.841.083-1.616.5-2.152 1.158-.537.658-.792 1.503-.709 2.35.083.847.497 1.626 1.15 2.166l5.2 4.328 4.269-5.265c.542-.654.803-1.499.726-2.347-.077-.848-.486-1.631-1.137-2.175z"
          fill="#B0D3FF"
          opacity="0.4"
        />
      </g>
    </g>
  </svg>
);

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

import SelectProjectPageWrapper from './wrapper';

const ProjectQuery = gql`
  query {
    user {
      company {
        projects(first: 20) {
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              pk
              name
              modifiedAt
              prototypeUrl
            }
          }
        }
      }
      projects(first: 20) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            pk
            name
            modifiedAt
            prototypeUrl
          }
        }
      }
    }
  }
`;

const SelectProjectPage = graphql(ProjectQuery)(SelectProjectPageWrapper);

export default SelectProjectPage;

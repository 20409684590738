import React from 'react';
import PropTypes from 'prop-types';

import Align from '@marvelapp/marvel-ui/align';
import Popover from '@marvelapp/marvel-ui/popover';
import Grid, { GridItem } from '@marvelapp/marvel-ui/grid';
import Spacing from '@marvelapp/marvel-ui/spacing';

import ColorButton from './ColorButton';

class Colors extends React.Component {
  colors() {
    const colors = [];
    this.props.colors.map(color =>
      colors.push(
        <GridItem key={color}>
          <Align type="center">
            <ColorButton
              onClick={() => {
                if (!this.props.setColor) {
                  return;
                }
                this.props.setColor(color);
              }}
              color={color}
              active={color === this.props.currentColor}
              key={color}
            />
          </Align>
        </GridItem>
      )
    );
    return colors;
  }

  render() {
    return (
      <Popover align="left" caretOffset={7} width={150}>
        <Popover.Trigger>
          <ColorButton color={this.props.currentColor} active={false} />
        </Popover.Trigger>
        <Popover.Content>
          <div>
            <Spacing size="m" type="padding">
              <Grid columns={3} size="s">
                {this.colors()}
              </Grid>
            </Spacing>
          </div>
        </Popover.Content>
      </Popover>
    );
  }
}

Colors.propTypes = {
  currentColor: PropTypes.string,
  colors: PropTypes.array,
  setColor: PropTypes.func,
};

export default Colors;

import React from 'react';

export const YellowLine = () => (
  <svg width="1255" height="418" viewBox="0 0 1255 418">
    <path
      stroke="#FD0"
      strokeWidth="40"
      fill="none"
      d="M0 20h717.775v377.848h537.225"
    />
  </svg>
);

import React from 'react';

export const YellowSquare = () => (
  <svg width="619" height="267" viewBox="0 0 619 267">
    <path
      stroke="#FD0"
      strokeWidth="40"
      fill="none"
      d="M0 20h598.168v227h-301"
    />
  </svg>
);

import React from 'react';
import Heading from '@marvelapp/marvel-ui/heading';
import Spacing from '@marvelapp/marvel-ui/spacing';

import { PlayIcon } from './PlayIcon';

const classNames = [
  'bg-white',
  'display-flex',
  'alignItems-center',
  'cursor-pointer',
  'justifyContent-center',
  'fill-green',
  'fill-green--d--hover',
  'scaleUp--hover',
  'transitionDuration-l',
  'transitionProperty-transform',
  'transitionTimingFunction-cb--scaleUp',
];

const PlayButton = () => (
  <div
    className={classNames.join(' ')}
    style={{
      height: '50px',
      paddingLeft: '25px',
      paddingRight: '25px',
      borderRadius: '25px',
      boxShadow: '0 1px 3px 0 rgba(135,149,169,0.30)',
    }}
  >
    <PlayIcon />
    <Heading tag="h2" weight="semibold" size="m">
      <Spacing size="s" position="left" type="padding">
        Play prototype
      </Spacing>
    </Heading>
  </div>
);

export default PlayButton;

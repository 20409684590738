import React from 'react';

export const Icon1 = () => (
  <svg width="100%" viewBox="0 0 185 154" xmlns="http://www.w3.org/2000/svg">
    <g fill="none">
      <path fill="#fff" d="M-239-1711h1440v2843h-1440z" />
      <path fill="#fff" d="M0 0h185v154h-185z" />
      <g transform="translate(21 11)">
        <ellipse
          id="Oval"
          fill="#F2F2F2"
          cx="82.247"
          cy="126.611"
          rx="30.036"
          ry="3.234"
        />
        <path
          d="M63.857 74.604l-1.124 46.108c-.009.386.022.772.09 1.151.084.46.225.907.42 1.332l-2.939-.071c-.577-.014-1.117.282-1.418.775-.3.493-.315 1.11-.039 1.617.276.507.802.828 1.379.842l8.055.194c1.507.036 2.967-.529 4.057-1.571 1.091-1.042 1.722-2.476 1.756-3.985l1.124-46.108c.05-2.033-.988-3.939-2.722-4.999-1.734-1.06-3.901-1.113-5.684-.139-1.784.974-2.913 2.826-2.962 4.859l.006-.006zM94.804 75.251l1.124 46.108c.009.386-.022.772-.09 1.151-.084.46-.225.907-.42 1.332l2.939-.071c.892-.021 1.632.685 1.654 1.578.021.893-.684 1.634-1.576 1.656l-8.055.194c-1.507.036-2.967-.529-4.057-1.571-1.091-1.042-1.722-2.476-1.756-3.985l-1.124-46.108c-.077-3.143 2.406-5.754 5.545-5.831 3.139-.077 5.746 2.409 5.823 5.552l-.006-.006z"
          id="Shape"
          fill="#fd0"
        />
        <path
          id="Shape"
          fill="#FFCD01"
          d="M95.023 84.571h-11.375l.194 7.761h11.375zM63.624 84.571l-.2 7.761h11.381l.194-7.761z"
        />
        <path
          d="M66.977 54.244s-12.641 0-16.433 22.779 3.792 48.087-17.059 49.355c-20.851 1.268-36.017-1.901-32.839-3.163 3.178-1.261 21.484-3.163 16.433-3.797-5.051-.634-9.792-4.747-9.792-4.747"
          id="Shape"
          stroke="#fd0"
          strokeWidth="3.234"
        />
        <path
          d="M79.017 88.452c-20.869 0-37.787-16.94-37.787-37.836 0-20.896 16.918-37.836 37.787-37.836s37.787 16.94 37.787 37.836c-.025 20.886-16.928 37.811-37.787 37.836zm0-49.93c-6.671 0-12.079 5.415-12.079 12.095 0 6.68 5.408 12.095 12.079 12.095s12.079-5.415 12.079-12.095c-.007-6.677-5.411-12.087-12.079-12.095z"
          id="Shape"
          fill="#fd0"
        />
        <path
          id="Rectangle-path"
          fill="#592B19"
          d="M107.931 54.499l-.463 2.543-28.671-5.216.463-2.543z"
        />
        <ellipse
          id="Oval"
          fill="#592B19"
          transform="rotate(-79.69 79.262 50.602)"
          cx="79.262"
          cy="50.602"
          rx="3.514"
          ry="3.518"
        />
        <ellipse
          id="Oval"
          fill="#FFCD01"
          cx="106.979"
          cy="55.919"
          rx="5.31"
          ry="5.316"
        />
        <path
          d="M128.328 69.049c-.812 0-1.616-.154-2.371-.453l-16.833-6.662c-3.294-1.328-4.899-5.07-3.594-8.376 1.305-3.306 5.031-4.938 8.341-3.654l1.008.395c.137-2.86 2.135-5.289 4.912-5.971 2.777-.682 5.671.544 7.115 3.015l7.002 11.965c1.172 2 1.185 4.476.033 6.488-1.152 2.012-3.291 3.253-5.607 3.252h-.006z"
          id="Shape"
          fill="#FFCD01"
        />
        <path
          d="M71.892 25.748c-.078-1.749-1.517-3.127-3.265-3.127-1.749 0-3.187 1.378-3.265 3.127M92.678 25.748c-.078-1.749-1.517-3.127-3.265-3.127-1.749 0-3.187 1.378-3.265 3.127"
          id="Shape"
          stroke="#592B19"
          strokeWidth="1.891"
          strokeLinecap="square"
        />
        <path
          d="M85.102 29.182c.072 2.223-1.071 4.309-2.982 5.441-1.911 1.133-4.287 1.133-6.199 0-1.911-1.133-3.054-3.219-2.982-5.441"
          id="Shape"
          fill="#fff"
        />
        <path
          d="M95.488 62.581h9.689l1.796 8.369-3.469-1.255s-24.365 20.16-44.847-1.94c0 0 22.181 15.315 38.698-1.294l-1.867-3.881z"
          id="Shape"
          fill="#fff"
        />
        <ellipse
          fill="#FFCD01"
          transform="rotate(-34.23 28.408 10.323)"
          cx="28.408"
          cy="10.323"
          rx="5.684"
          ry="5.692"
        />
        <path
          d="M26.099 6.955l-.906-1.33 6.67-4.541c.735-.492 1.729-.3 2.227.431.5.735.309 1.736-.426 2.237l-5.349 3.641c-.735.483-1.722.288-2.216-.438z"
          id="Shape"
          fill="#FFCD01"
        />
        <path
          d="M28.408 10.329l25.908 38.133"
          id="Shape"
          stroke="#FFCD01"
          strokeWidth="11.388"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M93.163 13.77c-5.708 0-10.335 4.633-10.335 10.348 0 5.715 4.627 10.348 10.335 10.348 5.708 0 10.335-4.633 10.335-10.348 0-5.715-4.627-10.348-10.335-10.348zm0 18.109c-4.281 0-7.751-3.475-7.751-7.761s3.47-7.761 7.751-7.761c4.281 0 7.751 3.475 7.751 7.761-.011 4.279-3.478 7.742-7.751 7.742v.019zM65.362 13.77c-5.708 0-10.335 4.633-10.335 10.348 0 5.715 4.627 10.348 10.335 10.348 5.708 0 10.335-4.633 10.335-10.348 0-5.715-4.627-10.348-10.335-10.348zm0 18.109c-4.281 0-7.751-3.475-7.751-7.761s3.47-7.761 7.751-7.761c4.281 0 7.751 3.475 7.751 7.761-.011 4.279-3.478 7.742-7.751 7.742v.019z"
          id="Shape"
          fill="#FD93B2"
        />
        <path fill="#FD93B2" d="M75.529 22.443h7.848v4.191h-7.848z" />
        <path
          d="M124.878 81.305c-1.101.742-2.594.453-3.339-.647-.361-.57-1.044-.846-1.699-.686-.482.126-.873.477-1.052.943-.178.466-.121.989.154 1.405l.291.44c.287.428.392.954.291 1.46-.101.506-.398.951-.828 1.237-.569.361-.845 1.046-.685 1.701.126.483.476.875.942 1.053.465.178.988.121 1.403-.154l.052-.032c.428-.288.953-.393 1.458-.292.505.101.95.399 1.235.829.361.57 1.044.846 1.699.686.482-.126.873-.477 1.052-.943.178-.466.121-.989-.154-1.405-.611-.919-.365-2.159.549-2.775l.388-.259c.413-.28.666-.742.679-1.241.013-.499-.216-.974-.615-1.275-.535-.403-1.267-.421-1.822-.045zM124.878 26.33c-1.101.742-2.594.453-3.339-.647-.361-.57-1.044-.846-1.699-.686-.482.126-.873.477-1.052.943-.178.466-.121.989.154 1.405l.291.44c.287.428.392.954.291 1.46-.101.506-.398.951-.828 1.237-.569.361-.845 1.046-.685 1.701.126.483.476.875.942 1.053.465.178.988.121 1.403-.154l.052-.032c.428-.288.953-.393 1.458-.292.505.101.95.399 1.235.829.361.57 1.044.846 1.699.686.482-.126.873-.477 1.052-.943.178-.466.121-.989-.154-1.405-.611-.919-.365-2.159.549-2.775l.388-.259c.413-.28.666-.742.679-1.241.013-.499-.216-.974-.615-1.275-.535-.403-1.267-.421-1.822-.045zM26.05 58.021c-1.101.742-2.594.453-3.339-.647-.361-.57-1.044-.846-1.699-.686-.482.126-.873.477-1.052.943-.178.466-.121.989.154 1.405l.291.44c.287.428.392.954.291 1.46-.101.506-.398.951-.828 1.237-.569.361-.845 1.046-.685 1.701.126.483.476.875.942 1.053.465.178.988.121 1.403-.154l.052-.032c.428-.288.953-.393 1.458-.292.505.101.95.399 1.235.829.361.57 1.044.846 1.699.686.482-.126.873-.477 1.052-.943.178-.466.121-.989-.154-1.405-.611-.919-.365-2.159.549-2.775l.388-.259c.413-.28.666-.742.679-1.241.013-.499-.216-.974-.615-1.275-.535-.403-1.267-.421-1.822-.045z"
          fill="#B0D3FF"
          opacity="0.4"
        />
      </g>
    </g>
  </svg>
);

import React from 'react';
import { PinkArrow } from './svg/PinkArrow';
import { PinkLine } from './svg/PinkLine';
import { YellowLine } from './svg/YellowLine';
import { YellowArrow } from './svg/YellowArrow';
import { YellowSquare } from './svg/YellowSquare';

import ParallaxWrapper from '../ParallaxWrapper';

import styles from './style/shapes.css';

export default function Shapes() {
  return (
    <div className={styles.shapes}>
      <div className={styles.pinkArrow}>
        <ParallaxWrapper speed={1.5}>
          <PinkArrow />
        </ParallaxWrapper>
      </div>
      <div className={styles.pinkLine}>
        <ParallaxWrapper speed={-2.5}>
          <PinkLine />
        </ParallaxWrapper>
      </div>
      <div className={styles.yellowLine}>
        <ParallaxWrapper speed={-4}>
          <YellowLine />
        </ParallaxWrapper>
      </div>
      <div className={styles.yellowSquare}>
        <YellowSquare />
      </div>
      <div className={styles.yellowArrow}>
        <ParallaxWrapper speed={-2.2}>
          <YellowArrow />
        </ParallaxWrapper>
      </div>
    </div>
  );
}

import React from 'react';
import PropTypes from 'prop-types';

import Input from '@marvelapp/marvel-ui/input';
import Button from '@marvelapp/marvel-ui/button';
import Position from '@marvelapp/marvel-ui/position';
import Heading from '@marvelapp/marvel-ui/heading';
import Spacing from '@marvelapp/marvel-ui/spacing';

class PasswordProtection extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { password: '' };
  }

  changedPassword = event => {
    this.setState({ password: event.target.value });
  };

  submit = () => {
    this.props.onSubmit(this.state.password);
  };

  render() {
    return (
      <Position zIndex={600} type="absolute" location="center">
        <Spacing type="margin" position="bottom" size="m">
          <Heading>This prototype is password protected</Heading>
        </Spacing>
        <Spacing type="margin" position="bottom" size="m">
          <Input
            placeholder="Your password"
            value={this.state.password}
            hasError={this.props.wrongPassword}
            size="large"
            type="password"
            onChange={this.changedPassword}
            fullWidth
          />
        </Spacing>
        <Button
          onClick={this.submit}
          background="green"
          hover="green"
          size="large"
          fullWidth
        >
          Submit
        </Button>
      </Position>
    );
  }
}

PasswordProtection.propTypes = {
  onSubmit: PropTypes.func,
  wrongPassword: PropTypes.bool,
};

export default PasswordProtection;

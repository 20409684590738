import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Select from '@marvelapp/marvel-ui/select';
import Button from '@marvelapp/marvel-ui/button';
import PageWrap from '@marvelapp/marvel-ui/page-wrap';
import Spacing from '@marvelapp/marvel-ui/spacing';

class SelectProjectPageWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.projects = [];
    this.state = {
      selectedProjectUrl: '',
    };
  }

  onClickSelectButton = () => {
    const shareHash = this.state.selectedProjectUrl.split('/').pop();
    this.onGenerate(shareHash);
  };

  onChange = event => {
    this.setState({ selectedProjectUrl: event.target.value });
  };

  onGenerate = id => {
    const path = `sitemap/${id}`;
    this.props.history.push(path);
  };

  removeDuplicateProjects(projects) {
    var newProjects = [];
    projects.forEach(function(project) {
      if (newProjects.some(e => e.node.pk === project.node.pk) === false) {
        newProjects.push(project);
      }
    });
    return newProjects;
  }

  orderByLastModified(projects) {
    var projectsSorted = projects.sort(function(project1, project2) {
      var dateA = moment(project1.node.modifiedAt);
      var dateB = moment(project2.node.modifiedAt);
      return dateA < dateB;
    });

    return projectsSorted;
  }

  userToProjects(user) {
    if (user.projects === undefined) {
      return [];
    }

    var projects = user.projects.edges;

    if (user.company) {
      const companyProjects = user.company.projects.edges;
      projects = [...projects, ...companyProjects];
    }

    const projectsWithoutDuplicates = this.removeDuplicateProjects(projects);
    const projectsOrdered = this.orderByLastModified(projectsWithoutDuplicates);

    return projectsOrdered;
  }

  render() {
    const { user, error, loading } = this.props.data;

    if (error) {
      return <div>Something went wrong retrieving your latest images...</div>;
    }

    if (loading) {
      return <div>loading</div>;
    }

    this.projects = this.userToProjects(user);

    if (this.projects.length === 0) {
      return <div>Your account has currently no images.</div>;
    }

    var options = this.projects.map(project => ({
      value: project.node.prototypeUrl,
      label: project.node.name,
    }));

    return (
      <PageWrap>
        <Spacing type="padding" size="xl" position="top">
          <Select onChange={this.onChange} options={options} size="xl" />
          <Button onClick={this.onClickSelectButton} size="large">
            Generate sitemap
          </Button>
        </Spacing>
      </PageWrap>
    );
  }
}

SelectProjectPageWrapper.propTypes = {
  data: PropTypes.shape({
    loading: PropTypes.bool,
    error: PropTypes.object,
    user: PropTypes.object,
  }).isRequired,
};

export default SelectProjectPageWrapper;

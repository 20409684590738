import React from 'react';
import UrlPattern from 'url-pattern';
import { withRouter, Link } from 'react-router-dom';

import styles from './style/generator.css';

const exampleUrl = '/100cjb3';

export class Generator extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: '', showInvalidUrl: false };
  }

  handleChange = event => {
    this.setState({ value: event.target.value });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.generate();
  };

  onClickGenerate = () => {
    this.generate();
  };

  generate = () => {
    const value = this.state.value;
    const pattern = new UrlPattern('(*)marvelapp.com/prototype(/:id)(/*)');
    const match = pattern.match(value);

    if (match === null) {
      this.setState({ showInvalidUrl: true });
      return;
    }

    this.props.history.push(match.id);
  };

  render() {
    return (
      <div className={styles.generator}>
        {this.state.showInvalidUrl && (
          <div className={styles.error}>
            Your url doesn{"'"}t seem to be a valid.
          </div>
        )}
        <div className={styles.enterUrl}>Enter URL</div>
        <form onSubmit={this.handleSubmit}>
          <input
            className={styles.field}
            type="text"
            name="lname"
            value={this.state.value}
            onChange={this.handleChange}
            placeholder="Share URL (eg marvelapp.com/prototype/qrqfe)"
          />
        </form>
        <div className={styles.actions}>
          <div className={styles.buttonGenerate} onClick={this.onClickGenerate}>
            Generate
          </div>
          <Link className={styles.exampleLink} to={exampleUrl}>
            Check out an example
          </Link>
        </div>
      </div>
    );
  }
}

export default withRouter(Generator);

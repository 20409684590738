import React from 'react';

import { Icon1 } from './svg/Icon1';
import { Icon2 } from './svg/Icon2';
import { Icon3 } from './svg/Icon3';

import styles from './style/blocks.css';

const contentData = [
  {
    title: 'Save time',
    icon: <Icon1 />,
    description:
      'Generate user flow diagrams for complex prototypes with one click - save yourself the time and resources wasted on printing workflows',
  },
  {
    title: 'Delight users',
    icon: <Icon2 />,
    description:
      "With Userflows it's easy to check site hierarchy and identify relationships between pages and sections, putting UX at the forefront of your product",
  },
  {
    title: 'Present faster',
    icon: <Icon3 />,
    description:
      'Communicate user flows and site structure effortlessly to colleagues, clients and stakeholders in a simple, easy to follow format',
  },
];

export function Blocks() {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.title}>
          Generate user flows from Marvel prototypes
        </h2>
        <p className={styles.subtitle}>
          Userflows quickly converts prototypes into user journey diagrams,
          displaying the detailed flows behind every interaction.
        </p>
      </div>
      <div className={styles.grid}>
        {contentData.map(item => (
          <div className={styles.gridItem} key={item.title}>
            <div className={styles.gridIcon}>{item.icon}</div>
            <h3 className={styles.gridItemTitle}>{item.title}</h3>
            <p className={styles.gridItemDescription}>{item.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Blocks;

import React from 'react';
import PropTypes from 'prop-types';
import { TickIcon } from './TickIcon';

class ColorButton extends React.PureComponent {
  render() {
    const { color, onClick, active } = this.props;
    const style = { backgroundColor: color, width: 25, height: 25 };
    return (
      <div
        role="button"
        tabIndex={0}
        className="borderRadius-circle cursor-pointer display-inlineBlock userSelect-none opacity-90--hover outlineStyle-none"
        style={style}
        onClick={onClick}
      >
        <div className="display-flex alignItems-center height-100">
          <div className="centered">{active && <TickIcon />}</div>
        </div>
      </div>
    );
  }
}

ColorButton.propTypes = {
  color: PropTypes.string,
  active: PropTypes.bool,
  onClick: PropTypes.func,
};

export default ColorButton;

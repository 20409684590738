import React from 'react';
import { Decimal } from 'decimal.js';
import { Link } from 'react-router-dom';
import Sitemap from '@marvelapp/marvel-sitemap';
import { InlineGrid } from '@marvelapp/marvel-ui/grid';
import Paragraph from '@marvelapp/marvel-ui/paragraph';
import Loader from '@marvelapp/marvel-ui/loader';
import Spacing from '@marvelapp/marvel-ui/spacing';
import Align from '@marvelapp/marvel-ui/align';
import Position from '@marvelapp/marvel-ui/position';

import PasswordProtection from './PasswordProtection/';
import ErrorPlaceholder from './ErrorPlaceholder/';
import ExportButton from './ExportButton/';
import Settings from './Settings/';
import Colors from './Colors/';
import Controls from './Controls/';
import PlayButton from './PlayButton/';
import BackButton from './BackButton/';

export class SitemapPage extends React.Component {
  constructor() {
    super();
    this.state = {
      menu: {
        showConfig: false,
        showColors: false,
      },
      sitemapLoaded: false,
      loading: true,
      needsPassword: false,
      projectNotFound: false,
      wrongPassword: false,
      noHotspots: false,
      config: {
        lineWidth: 2,
        arrow: 3,
        lineColor: '#AD6EDD',
        spacingX: 150,
        scale: 0.2,
      },
      colors: [
        '#1EB6FF',
        '#60D2C9',
        '#44C553',
        '#FF7047',
        '#AD6EDD',
        '#FFAE30',
        '#222D39',
        '#8792A1',
        '#C3CCD7',
      ],
    };
  }

  componentDidMount() {
    this.loadSitemap();
    document.addEventListener(
      'keydown',
      ev => {
        this.keyDown(ev);
      },
      false
    );
  }

  componentDidUpdate() {
    const conf = this.state.config;
    this.sitemap.update(conf);
  }

  componentWillUnmount() {
    this.sitemap.destroy();
  }

  loadSitemap = token => {
    const { id } = this.props.match.params;
    this.sitemap = new Sitemap({ id, canvasId: 'vis', token });
    this.sitemap.load();
    this.sitemap.listenForEvents(this.receivedEvent);
  };

  receivedEvent = event => {
    switch (event) {
      case 'sitemap.PROJECT_DATA_LOADED':
        this.setState({
          sitemapLoaded: true,
          loading: false,
        });
        break;

      case 'sitemap.AUTHENTICATION_REQUIRED':
        this.setState({
          needsPassword: true,
          loading: false,
        });
        break;

      case 'sitemap.AUTHENTICATION_SUCCESS':
        this.setState({
          needsPassword: false,
        });
        break;

      case 'sitemap.AUTHENTICATION_FAILED':
        this.setState({
          wrongPassword: true,
        });
        break;

      case 'sitemap.PROJECT_NOT_FOUND':
        this.setState({
          projectNotFound: true,
          loading: false,
        });
        break;

      case 'sitemap.NO_HOTSPOTS':
        this.setState({
          noHotspots: true,
        });
        break;

      default:
        console.log(`No case for ${event}`);
    }
  };

  onChange = evt => {
    const conf = Object.assign({}, this.state.config);
    conf[evt.target.name] = Number(evt.target.value);
    this.setState({ config: conf });
  };

  setScale = scale => {
    const conf = Object.assign({}, this.state.config);
    conf.scale = scale;
    this.setState({ config: conf });
  };

  setColor = c => {
    const conf = Object.assign({}, this.state.config);
    conf.lineColor = c;
    this.setState({ config: conf });
  };

  keyDown = evt => {
    const commandPressed = evt.ctrlKey || evt.metaKey;

    // Zoom in
    if (commandPressed && evt.key === '=') {
      evt.preventDefault();
      const maxScale = new Decimal(1.0);
      const newScale = new Decimal(this.state.config.scale).plus(0.1);

      if (newScale.greaterThan(maxScale)) {
        return;
      }

      this.setScale(newScale.toFixed(1));
    }

    // Zoom out
    if (commandPressed && evt.key === '-') {
      evt.preventDefault();

      const minScale = new Decimal(0.1);
      const newScale = new Decimal(this.state.config.scale).minus(0.1);

      if (newScale.lessThan(minScale)) {
        return;
      }

      this.setScale(newScale.toFixed(1));
    }
  };

  play = () => {
    const prototypeShareId = this.sitemap.options.id;
    window.open(
      `https://marvelapp.com/prototype/${prototypeShareId}`,
      '_blank'
    );
  };

  password = pwd => {
    this.sitemap.password(pwd);
  };

  exportImage = () => {
    this.sitemap.exportImage().then(result => {
      const downloadLink = document.createElement('a');
      downloadLink.setAttribute('href', result);
      downloadLink.setAttribute('download', 'flowmap');
      downloadLink.style.display = 'none';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
  };

  render() {
    const deviderLine = (
      <div className="bg-smoke" style={{ width: '1px', height: '50px' }} />
    );

    const {
      loading,
      needsPassword,
      wrongPassword,
      colors,
      sitemapLoaded,
      projectNotFound,
      noHotspots,
      config,
      menu,
    } = this.state;

    return (
      <div className="bg-snow--d position-absolute pinAll zi-weak">
        {loading && (
          <Position zIndex={600} type="absolute" location="center">
            <Loader size="extraLarge" />
          </Position>
        )}

        {projectNotFound && (
          <ErrorPlaceholder
            title="Your share url isn't valid"
            subtitle="Make sure you copied the share url correctly."
          />
        )}

        {needsPassword && (
          <PasswordProtection
            wrongPassword={wrongPassword}
            onSubmit={this.password}
          />
        )}

        {noHotspots && (
          <ErrorPlaceholder
            title="This prototype has no hotspots."
            subtitle="Use a prototype with at least one hotspot."
          />
        )}

        <div className="controls position-absolute pinTL padding-l zi-overlay">
          <Link to="/">
            <BackButton />
          </Link>
        </div>

        <div
          className={
            sitemapLoaded && noHotspots === false ? '' : 'display-none'
          }
        >
          <div className="controls margin-l position-absolute pinBL zi-overlay">
            <Controls>
              <InlineGrid size="none">
                <Spacing size="m" position="left" type="margin">
                  <Spacing size="s" position="right" type="margin">
                    <Colors
                      colors={colors}
                      setColor={this.setColor}
                      currentColor={config.lineColor}
                    />
                  </Spacing>
                </Spacing>
                {deviderLine}
                <Spacing size="m" position="leftRight" type="margin">
                  <InlineGrid size="xs">
                    <input
                      name="scale"
                      type="range"
                      value={this.state.config.scale}
                      min={0.1}
                      max={1}
                      step={0.1}
                      onChange={this.onChange}
                      className="range range--light"
                    />
                    <div style={{ minWidth: '28px' }}>
                      <Align type="right">
                        <Paragraph color="silver" size="xs">
                          {Math.floor(this.state.config.scale * 100)}%
                        </Paragraph>
                      </Align>
                    </div>
                  </InlineGrid>
                </Spacing>
                {deviderLine}
                <Spacing size="s" position="left" type="margin">
                  <Spacing size="m" position="right" type="margin">
                    <Settings
                      config={config}
                      onChange={this.onChange}
                      visible={menu.showConfig}
                    />
                  </Spacing>
                </Spacing>
              </InlineGrid>
            </Controls>
          </div>

          <div className="controls position-absolute pinTR padding-l zi-overlay">
            <div
              className="userSelect-none outlineStyle-none"
              role="button"
              tabIndex={0}
              onClick={this.play}
            >
              <PlayButton />
            </div>
          </div>

          <div className="controls position-absolute pinBR padding-l zi-overlay">
            <div
              className="userSelect-none outlineStyle-none"
              role="button"
              tabIndex={0}
              onClick={this.exportImage}
            >
              <ExportButton />
            </div>
          </div>
        </div>

        <canvas
          className={`position-absolute top-0 left-0 width-100 height-100 zi-weak ${
            noHotspots ? 'display-none' : ''
          }`}
          resize="true"
          id="vis"
        />
      </div>
    );
  }
}

export default SitemapPage;

import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';

import '@marvelapp/marvel-ui/lib/styles.css';
import '@marvelapp/marveloucss/marveloucss.css';

import ReactGA from 'react-ga';
import createHistory from 'history/createBrowserHistory';

import App from 'components/App';
import * as serviceWorker from './serviceWorker';

const history = createHistory();

ReactGA.initialize('UA-38711157-5');

history.listen((location, action) => {
  ReactGA.set({ page: location.pathname });
  let pageName = '/';
  if (!(location.pathname === '/')) {
    pageName = 'flow-view';
  }
  ReactGA.pageview(pageName);
});

ReactDOM.render(
  <Router history={history}>
    <App />
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

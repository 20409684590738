import React from 'react';
import PropTypes from 'prop-types';

import Popover, { PopoverOption } from '@marvelapp/marvel-ui/popover';

import SettingsItem from './SettingsItem';
import { SettingsIcon } from './SettingsIcon';

class Settings extends React.PureComponent {
  render() {
    const { lineWidth, arrow, spacingX } = this.props.config;
    return (
      <Popover width={285}>
        <Popover.Trigger>
          <div className="fill-silver fill-smoke--xd--hover cursor-pointer">
            <SettingsIcon />
          </div>
        </Popover.Trigger>
        <Popover.Content>
          <div>
            <SettingsItem
              onChange={this.props.onChange}
              targetName="lineWidth"
              name="Line Width"
              min={1}
              max={10}
              value={lineWidth}
            />
            <PopoverOption isSep />
            <SettingsItem
              onChange={this.props.onChange}
              targetName="arrow"
              name="Arrow Size"
              min={2}
              max={10}
              value={arrow}
            />
            <PopoverOption isSep />
            <SettingsItem
              onChange={this.props.onChange}
              targetName="spacingX"
              name="Spacing"
              min={10}
              max={300}
              value={spacingX}
            />
          </div>
        </Popover.Content>
      </Popover>
    );
  }
}

Settings.propTypes = {
  config: PropTypes.object,
  onChange: PropTypes.func,
};

export default Settings;

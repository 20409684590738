import React, { Component } from 'react';

import { MarvelLogo } from './svg/MarvelLogo';

import styles from './style/badge.css';

class Badge extends Component {
  openDevelopers = () => {
    window.open('https://marvelapp.com/developers');
  };

  render() {
    return (
      <div onClick={this.openDevelopers} className={styles.badge}>
        <div>a</div>
        <div>
          <div className={styles.badgeMarvelLogo}>
            <MarvelLogo />
          </div>
        </div>
        <div>integration</div>
      </div>
    );
  }
}

export default Badge;

import React from 'react';

export const Facebook = () => (
  <svg width="30" height="30" viewBox="0 0 30 30">
    <path
      fill="#8792A1"
      fillRule="evenodd"
      d="M54.3945312,4.39453125 C51.4648291,7.32423375 50,10.859355 50,15 C50,19.140645 51.4648291,22.6757663 54.3945312,25.6054688 C57.3242337,28.5351709 60.859355,30 65,30 C69.140645,30 72.6757663,28.5351709 75.6054688,25.6054688 C78.5351713,22.6757663 80,19.140645 80,15 C80,10.859355 78.5351713,7.32423375 75.6054688,4.39453125 C72.6757663,1.46482875 69.140645,0 65,0 C60.859355,0 57.3242337,1.46482875 54.3945312,4.39453125 Z M68.6374512,15 L66.0004883,15 L66.0004883,24.0234375 L63,24.0234375 L63,15 L61,15 L61,12 L63,12 L63,10.078125 C63.0664062,8.71093125 63.3203113,7.68555 63.828125,7.001955 C64.3359387,6.31835625 65.2929613,5.9765625 66.6992188,5.9765625 L68.9831543,5.9765625 L68.9831543,9.04052734 L67.2368164,9.04052734 C66.6899389,9.04052734 66.3481452,9.13818109 66.2114277,9.33349609 C66.0747064,9.52881109 66.0063477,9.90234 66.0063477,10.3710938 L66.0004883,12 L68.9831543,12 L68.6374512,15 Z"
      transform="translate(-50)"
    />
  </svg>
);

import React from 'react';

import PreviewImage from './img/preview.png';
import PreviewImage2x from './img/preview@2x.png';

import Blocks from './blocks';
import Footer from './footer';
import Upsell from './upsell';
import Badge from './badge';
import Shapes from './shapes';
import Generator from './generator';

import { Logo } from './svg/Logo';

import styles from './style/main.css';

export function HomePage() {
  return (
    <div className={styles.page}>
      <Badge />
      <Shapes />
      <div className={styles.container}>
        <div className={styles.header}>
          <Logo />
          <h1 className={styles.title}>Create user journeys, in seconds</h1>
          <p className={styles.subtitle}>
            Paste in any Marvel share URL to automatically generate your
            prototype's user journey
          </p>
          <Generator />
        </div>
        <img
          alt="Marvel Sitemap Preview"
          className={styles.preview}
          src={PreviewImage}
          srcSet={`${PreviewImage2x} 2x`}
        />
      </div>
      <Blocks />
      <Upsell />
      <Footer />
    </div>
  );
}

export default HomePage;

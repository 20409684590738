import React from 'react';

export const TickIcon = () => (
  <svg width="9" height="8" viewBox="0 0 9 8">
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M1.929 3.685c-.207-.229-.496-.358-.798-.358-.302 0-.591.13-.798.358-.444.484-.444 1.243 0 1.727l2.24 2.228c.212.234.508.364.817.36.316.005.62-.128.837-.367l4.447-5.489c.434-.506.434-1.268 0-1.774-.211-.236-.507-.37-.817-.37-.31 0-.607.134-.817.37l-3.765 4.668-1.345-1.354z"
    />
  </svg>
);

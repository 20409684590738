import React from 'react';
import PropTypes from 'prop-types';
import Paragraph from '@marvelapp/marvel-ui/paragraph';
import Grid, { GridItem } from '@marvelapp/marvel-ui/grid';
import Spacing from '@marvelapp/marvel-ui/spacing';
import Align from '@marvelapp/marvel-ui/align';

class SettingsItem extends React.PureComponent {
  render() {
    return (
      <Spacing size="m" type="padding">
        <Grid
          size="s"
          columns={10}
          alignItems="center"
          justifyContent="space-between"
        >
          <GridItem columnSpan={4}>
            <Paragraph color="silver" size="s">
              {this.props.name}
            </Paragraph>
          </GridItem>
          <GridItem columnSpan={3}>
            <input
              name={this.props.targetName}
              type="range"
              value={this.props.value}
              min={this.props.min}
              max={this.props.max}
              step={1}
              onChange={this.props.onChange}
              className="range range--light"
            />
          </GridItem>
          <GridItem columnSpan={3}>
            <Align type="right">
              <Paragraph color="silver" size="xs">
                {this.props.value}
              </Paragraph>
            </Align>
          </GridItem>
        </Grid>
      </Spacing>
    );
  }
}

SettingsItem.propTypes = {
  onChange: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  name: PropTypes.string,
  targetName: PropTypes.string,
  value: PropTypes.number,
};

export default SettingsItem;

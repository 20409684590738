import React from 'react';
import { AuthTokenKey } from '../../utils/config';

class OAuthTokenRetrieve extends React.Component {
  render() {
    let accessToken = document.location.href.match(
      /#(?:access_token)=([\S\s]*?)&/
    )[1];
    localStorage.setItem(AuthTokenKey, accessToken);
    window.location.href = '/select-project';
    return null;
  }
}

export default OAuthTokenRetrieve;

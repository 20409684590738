import React from 'react';

import { Logo } from './svg/Logo';
import { Twitter } from './svg/Twitter';
import { Facebook } from './svg/Facebook';

import styles from './style/footer.css';

export class Footer extends React.PureComponent {
  openTwitter = () => {
    window.open(`https://twitter.com/marvelapp`, '_blank');
  };

  openFacebook = () => {
    window.open(`https://www.facebook.com/marvelapp`, '_blank');
  };

  render() {
    return (
      <div className={styles.footer}>
        <Logo />
        <div className={styles.text}>
          <p className={styles.copy}>
            Userflows is an integration made with Marvel’s Platform API
          </p>
          <p className={styles.copy}>©2018 Userflows, a Marvel product</p>
        </div>
        <div>
          <div className={styles.twitter} onClick={this.openTwitter}>
            <Twitter />
          </div>
          <div className={styles.facebook} onClick={this.openFacebook}>
            <Facebook />
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;

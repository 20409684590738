import React from 'react';

import { LeftArrow } from './LeftArrow';

const classNames = [
  'bg-white',
  'borderRadius-circle',
  'display-flex',
  'alignItems-center',
  'justifyContent-center',
  'fill-blue',
  'fill-blue--d--hover',
  'scaleUp--hover',
  'transitionDuration-l',
  'transitionProperty-transform',
  'transitionTimingFunction-cb--scaleUp',
];

const BackButton = () => (
  <div
    className={classNames.join(' ')}
    style={{
      width: '50px',
      height: '50px',
      boxShadow: '0 1px 3px 0 rgba(135,149,169,0.30)',
    }}
  >
    <LeftArrow />
  </div>
);

export default BackButton;

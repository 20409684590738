import React from 'react';
import PropTypes from 'prop-types';

class Controls extends React.PureComponent {
  render() {
    return (
      <div
        className="bg-white display-flex alignItems-center justifyContent-center cursor-default"
        style={{
          height: '50px',
          borderRadius: '25px',
          boxShadow: '0 1px 3px 0 rgba(135,149,169,0.30)',
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

Controls.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Controls;

import React from 'react';

export const Icon3 = () => (
  <svg width="100%" viewBox="0 0 185 154" xmlns="http://www.w3.org/2000/svg">
    <g fill="none">
      <path fill="#fff" d="M-1019-1711h1440v2843h-1440z" />
      <path fill="#fff" d="M0 0h185v154h-185z" />
      <g transform="translate(33 23)">
        <path
          d="M50.308 88.433h.515c1.324-.001 2.643.165 3.925.494 6.242-8.092 17.742-9.852 26.118-3.995l27.939-35.118-33.176-26.365-38.471 48.36c6.596 3.361 11.398 9.431 13.151 16.624z"
          id="Shape"
          fill="#B0D3FF"
          opacity="0.4"
        />
        <path
          d="M80.88 84.918c-8.376-5.856-19.875-4.097-26.118 3.995-1.282-.33-2.601-.496-3.925-.494h-.515c-2.605-10.795-11.926-18.642-23.006-19.369-11.081-.727-21.347 5.834-25.341 16.196-3.994 10.361-.786 22.116 7.916 29.014s20.879 7.336 30.055 1.082c5.403 5.228 13.76 5.877 19.906 1.546 8.816 5.438 20.359 2.88 26.052-5.774 5.693-8.654 3.474-20.266-5.01-26.21l-.014.014z"
          id="Shape"
          fill="#B0D3FF"
          opacity="0.3"
        />
        <path
          d="M94.024 33.353l-17.569 23.435"
          id="Shape"
          stroke="#B0D3FF"
          strokeWidth="42.353"
          opacity="0.4"
        />
        <path
          d="M116.315.805l-63.911 19.948c-.936.295-1.631 1.085-1.804 2.051-.173.966.204 1.949.979 2.551l60.706 46.92c.779.593 1.821.706 2.709.295.888-.411 1.475-1.28 1.527-2.257l3.233-66.868c.041-.863-.344-1.692-1.029-2.218-.685-.526-1.585-.684-2.409-.422z"
          id="Shape"
          fill="#fd0"
        />
        <path
          d="M50.294 43.793l-5.555 6.395M87.918 89.64l-5.548 6.402"
          id="Shape"
          stroke="#fd0"
          strokeWidth="7.059"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M105.339 25.348c1.963 1.441 2.981 3.839 2.654 6.252-.327 2.413-1.946 4.454-4.221 5.321-2.275.867-4.842.422-6.692-1.161"
          id="Shape"
          fill="#fff"
        />
        <circle
          id="Oval"
          fill="#FD93B2"
          transform="rotate(-48.42 88.654 35.386)"
          cx="88.654"
          cy="35.386"
          r="9.741"
        />
        <circle
          id="Oval"
          fill="#2D1100"
          transform="rotate(-48.42 88.654 35.386)"
          cx="88.654"
          cy="35.386"
          r="6.487"
        />
        <path
          id="Shape"
          stroke="#fff"
          strokeWidth="1.807"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M91.179 39.212l-4.673-1.256 3.353-4.744-4.673-1.256"
        />
        <circle
          id="Oval"
          fill="#FD93B2"
          transform="rotate(-48.42 104.665 17.335)"
          cx="104.665"
          cy="17.335"
          r="9.741"
        />
        <circle
          fill="#2D1100"
          transform="rotate(-48.42 104.665 17.335)"
          cx="104.665"
          cy="17.335"
          r="6.452"
        />
        <path
          stroke="#fff"
          strokeWidth="1.807"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M107.195 21.162l-4.673-1.249 3.353-4.751-4.673-1.256"
        />
        <path
          fill="#FD93B2"
          d="M93.069 26.821l3.986-4.529 3.019 2.657-3.986 4.529z"
        />
      </g>
    </g>
  </svg>
);

import React from 'react';

export const PinkLine = () => (
  <svg width="736" height="286" viewBox="0 0 736 286">
    <g
      fill="none"
      fillRule="evenodd"
      stroke="#FFA2BD"
      transform="translate(11 20)"
    >
      <polyline
        strokeWidth="40"
        points="42 0 633.865 0 633.865 230 724.5 230"
        transform="matrix(-1 0 0 1 766.5 0)"
      />
      <circle cx="25" cy="230" r="25" strokeWidth="21" />
    </g>
  </svg>
);

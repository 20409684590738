import React from 'react';
import PropTypes from 'prop-types';

import Heading from '@marvelapp/marvel-ui/heading';
import Spacing from '@marvelapp/marvel-ui/spacing';

export default function ErrorPlaceholder({ title, subtitle }) {
  return (
    <div className="display-table position-absolute pinAll zi-chrome">
      <div className="display-tableCell verticalAlign-middle padding-m textAlign-center">
        <Spacing size="xs" position="bottom" type="margin">
          <Heading tag="h2" weight="semibold" size="xl">
            {title}
          </Heading>
        </Spacing>
        <Heading tag="h2" weight="semibold" color="silver" size="m">
          {subtitle}
        </Heading>
      </div>
    </div>
  );
}

ErrorPlaceholder.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};
